




































































































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import { MetricWeightResponseModel } from "@/healthcheck/model/MetricWeightResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { HealthcheckAlertDestination } from "@/tools/models/healthcheck-alert/HealthcheckAlertModel";
import { HealthcheckAlertsSettingsModel } from "@/tools/models/healthcheck-alert/HealthcheckAlertsSettingsModel";
import { AppSection } from "@/shared/models";
import { DataTableHeader } from "vuetify";

@Component
export default class HealthcheckAlertsView extends mixins(
  AppSectionAccessMixin
) {
  search = "";
  file: File | null = null;
  uploadDialog = false;
  uploadFileStatisticsDialog = false;

  get destination(): HealthcheckAlertDestination {
    return {
      [AppSection.HEALTHCHECK_MARKETING_ALERT]:
        HealthcheckAlertDestination.MARKETING,
      [AppSection.HEALTHCHECK_MONETIZATION_ALERT]:
        HealthcheckAlertDestination.MONETIZATION,
    }[
      this.appSection as
        | AppSection.HEALTHCHECK_MARKETING_ALERT
        | AppSection.HEALTHCHECK_MONETIZATION_ALERT
    ];
  }

  get headers(): Array<DataTableHeader> {
    return [
      {
        text: this.$lang("tools.healthcheckAlerts.tableColumns.applicationId"),
        align: "start",
        value: "applicationId",
      },
      {
        text: this.$lang("tools.healthcheckAlerts.tableColumns.metricId"),
        value: "metricId",
      },
      {
        text: this.$lang("tools.healthcheckAlerts.tableColumns.country"),
        value: "country",
      },
      ...(this.destination === HealthcheckAlertDestination.MONETIZATION
        ? [
            {
              text: this.$lang("tools.healthcheckAlerts.tableColumns.adType"),
              value: "adType",
            },
          ]
        : []),
      {
        text: this.$lang("tools.healthcheckAlerts.tableColumns.absoluteDiff"),
        value: "absoluteDiff",
      },
      {
        text: this.$lang("tools.healthcheckAlerts.tableColumns.percentDiff"),
        value: "percentDiff",
      },
      {
        text: this.$lang("tools.healthcheckAlerts.tableColumns.operator"),
        value: "operator",
      },
      {
        text: this.$lang("tools.healthcheckAlerts.tableColumns.observedPeriod"),
        value: "observedPeriod",
      },
      {
        text: this.$lang("tools.healthcheckAlerts.tableColumns.basePeriod"),
        value: "basePeriod",
        sortable: false,
        align: "end",
      },
    ];
  }

  get faqUrl(): string {
    return {
      [HealthcheckAlertDestination.MARKETING]:
        "https://x-flow-ltd.atlassian.net/wiki/spaces/BIR/pages/845774849/Marketing+alerts+file+FAQ",
      [HealthcheckAlertDestination.MONETIZATION]:
        "https://x-flow-ltd.atlassian.net/wiki/spaces/BIR/pages/638615553/Monetization+alerts+file+FAQ",
    }[this.destination];
  }

  get isFileLoading(): boolean {
    return this.$store.state.healthcheckAlertsStore.isFileLoading;
  }

  get healthcheckAlerts(): Array<MetricWeightResponseModel> {
    return this.$store.state.healthcheckAlertsStore.healthcheckAlerts;
  }

  get isHealthcheckAlertsEmpty(): boolean {
    return !this.isHealthcheckAlertsLoading && !this.healthcheckAlerts.length;
  }

  get isHealthcheckAlertsLoading(): boolean {
    return this.$store.state.healthcheckAlertsStore.isHealthcheckAlertsLoading;
  }

  get healthcheckAlertsSettings(): HealthcheckAlertsSettingsModel | null {
    return this.$store.state.healthcheckAlertsStore.healthcheckAlertsSettings;
  }

  get fileUploadStatisticsValues() {
    return this.healthcheckAlertsSettings
      ? [
          {
            label: this.$lang(
              "tools.healthcheckAlerts.uploadFileStatisticsDialog.originalLines"
            ),
            value: this.healthcheckAlertsSettings.originalLinesCount,
          },
          {
            label: this.$lang(
              "tools.healthcheckAlerts.uploadFileStatisticsDialog.processedLines"
            ),
            value: this.healthcheckAlertsSettings.processedLinesCount,
          },
          {
            label: this.$lang(
              "tools.healthcheckAlerts.uploadFileStatisticsDialog.notProcessedLines"
            ),
            value: this.healthcheckAlertsSettings.notProcessedLinesCount,
          },
        ]
      : [];
  }

  get fileName(): string {
    return this.$store.state.healthcheckAlertsStore.fileName;
  }

  get title(): string {
    return this.$lang(
      `tools.healthcheckAlerts.title.${this.destination.toLowerCase()}`
    );
  }

  @Watch("destination", { immediate: true })
  changeDestination(destination: HealthcheckAlertDestination) {
    document.title = this.$lang("documentTitle", this.title);
    this.$store.commit("resetHealthcheckAlertsStore");
    this.$store.commit("setHealthcheckAlertsDestination", destination);
    this.$store.dispatch("getHealthcheckAlerts");
  }

  @Watch("file")
  onFileUpload(file: File) {
    this.uploadDialog = !!file?.size;
  }

  destroyed() {
    this.$store.commit("resetHealthcheckAlertsStore");
  }

  async uploadFile(file?: File | null) {
    if (file?.size) {
      await this.$store.dispatch("uploadHealthcheckAlertsFile", file);
      this.uploadFileStatisticsDialog = true;
    }

    await this.$store.dispatch("getHealthcheckAlerts");
    this.uploadDialog = false;
  }

  cancelUpload() {
    this.file = null;
    this.uploadDialog = false;
  }
}
